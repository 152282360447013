<!-- 页面底部 -->
<template>
    <div class="footer-container">
        <ul class="footer-ul">
            <li v-for="(item, index) in list" :key="item.title" class="footer-li">
                <p class="footer-title">{{ item.title }}</p>
                <i class="footer-icon"></i>
                <p v-for="(subtitle, i) in item.subtitle" :key="subtitle" class="footer-subtitle" :class="{'footer-pb-10' : index === 3 && i === 1 || i === 3}">{{ subtitle }}</p>
                <img v-if="index === 4" :src="require('@imgs/footer/code.png')" alt="" srcset="">
            </li>
        </ul>
        <div class="footer-auth">
            <p>
                ©{{ new Date().getFullYear() }} SHENGYE CAPITAL ALL RIGHTS RESERVED
                <a class="footer-icp" href="https://beian.miit.gov.cn" target="_blank">
                    京ICP备2022009433号</a>
            </p>
        </div>
    </div>
</template>

<script>
export default ({
    name: 'TheFooter',
    data() {
        return {
            list: [
                { title: '产品中心', subtitle: ['盛易通云平台', '智能中登服务', '发票验真', 'OCR识别'] },
                { title: '行业中心', subtitle: ['基建工程', '医药医疗', '能源化工', '其他行业'] },
                { title: '新闻中心', subtitle: ['公司新闻', '行业资讯'] },
                { title: '服务方式', subtitle: ['在线客服：400-066-9938'] },
                // { title: '服务方式', subtitle: ['在线客服：400-066-9938', '技术支持QQ：', '888888 (工作日9:00-18:00)', '意见邮箱：', 'AAAAA@126.com'] },
                { title: '关注我们', subtitle: ['盛易通云平台公众号'] }
            ]
        }
    }
})
</script>

<style lang="scss" scoped>
    .footer-container {
        height: 452px;
        background: #000101;
        border-top: 1px dashed #ffffff;
        color: #ffffff;
        padding-top: 41px;
        position: relative;
        .footer-ul {
            .footer-li {
                vertical-align: top;
                text-align: left;
                width: 240px;
                .footer-title {
                    line-height: 22px;
                }
                .footer-icon {
                    width: 20px;
                    height: 2px;
                    background: #ffffff;
                    opacity: 0.16;
                    display: inline-block;
                    margin: 8px 0 19px;
                }
                .footer-subtitle {
                    line-height: 20px;
                    font-size: 14px;
                    padding-bottom: 20px;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .footer-auth {
            padding: 30px 0 26px;
            position: absolute;
            bottom: 0;
            width: 100%;
            border-top: 1px solid #282929;
            p {
                text-align: center;
                height: 17px;
                font-size: 12px;
                font-weight: 300;
                color: #666666;
                line-height: 17px;
            }
        }
    }

    .footer-icp {
        padding-left: 10px;
        &:hover {
            text-decoration: underline;
        }
    }

    .footer-pb-10 {
        padding-bottom: 10px !important;
    }
</style>